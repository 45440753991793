<template>
  <div class="AfterSales p32 p-t-32">
    <div class="tt df">
      <div class="suxian"></div>
      订单报表
    </div>
    <div style="margin:0" class="serchtab">
      <div class="li">
        <div class="serchName">下单时间</div>

        <div class="ipt">
          <el-date-picker
            v-model="inputDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="li">
        <div class="serchName">是否集采</div>
        <div class="ipt">
          <el-select v-model="queryForm.erpOrderSource" placeholder="请选择" clearable @change="serchList">
            <el-option
              v-for="item in orderSourceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="serchtab">
      <div class="li">
        <div class="serchName">客户名称</div>
        <div class="ipt">
          <el-input placeholder="请输入客户名称" v-model="queryForm.member" clearable @keyup.native.enter="serchList">
          </el-input>
        </div>
      </div>
      <div class="li">
        <div class="serchName">订单编号</div>
        <div class="ipt">
          <el-input placeholder="请输入订单编号" v-model="queryForm.sn" clearable @keyup.native.enter="serchList">
          </el-input>
        </div>
      </div>
      <div class="li">
        <div class="serchName">支付方式</div>
        <div class="ipt">
          <el-select v-model="queryForm.payType" placeholder="请选择" clearable @change="serchList">
            <el-option
              v-for="item in payOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="serch">
        <el-button @click="serchList" type="success">搜索</el-button>
        <el-button @click="resetFn">重置</el-button>
      </div>
    </div>

    <div class="daifukuanhd tanxin f14">
      <div class=" df aic left">
        <img src="@/assets/images/Shape.png" style="margin-right:8px" alt="" />
        已查询到 <span style="color: #26c487"> {{ total }} </span>条订单报表信息
      </div>
      <div @click="downloadFile" class="right">
        <span class="cup" style="color: #26c487; margin-left: 5px"
          >导出结果</span
        >
      </div>
    </div>

    <div class="all mt20">
      <div class="header">
        订单信息汇总
      </div>
      <div class="mid">
        <div class="list">
          <div class="cont">
            <div class="hui">客户总数</div>
            {{ totalData.memberCount }}
          </div>
          <div class="cont">
            <div class="hui">订单总数</div>
            {{ totalData.orderCount }}
          </div>
          <div class="cont">
            <div class="hui">商品总数</div>
            {{ totalData.goodsCount }}
          </div>
        </div>
        <div class="list">
          <div class="cont">
            <div style="min-width: 85px;" class="hui tar">邮费总额</div>
            ¥{{ totalData.feeAmount }}
          </div>
          <div class="cont">
            <div class="hui">商家优惠总额</div>
            <div style="color:#FFA936">¥{{ totalData.couponAmount }}</div>
          </div>
          <div class="cont">
            <div class="hui">订单金额总额</div>
            ¥{{ totalData.orderAmount }}
          </div>
        </div>
        <div class="list">
          <div class="cont">
            <div class="hui">出库总额</div>
            <div style="color:#26C487">¥{{ totalData.exwarehouseAmount }}</div>
          </div>
          <div class="cont">
            <div class="hui">退款总额</div>
            <div style="color:#FFA936">¥{{ totalData.refundAmount }}</div>
          </div>
          <div class="cont">
            <div class="hui">成交总额</div>
            <div style="color:#E33233">¥{{ totalData.checkAmount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom:13px;margin-left:2px" class="header">
      订单明细查询
    </div>
    <div class="tabel">
      <el-table
        :cell-style="cellStyle"
        :header-cell-style="{ background: '#F8F8F8' }"
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column align="left" type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column
          align="center"
          property="statisticsTime"
          width="200"
          label="下单时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          property="member"
          label="客户名称"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          property="sn"
          label="订单编号"
          width="200"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="是否集采"
          width="100"
        >
          <template slot-scope="scope">
            <span>{{ getLabelByValue(scope.row.erpOrderSource, orderSourceOptions) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="goodsAmount"
          label="商品总额"
          width="120"
        >
          <template slot-scope="scope">
            <span>¥{{ scope.row.goodsAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="payType"
          label="支付方式"
          width="140"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.huiCoinDiscount > 0">慧金豆{{ formatMoney(scope.row.huiCoinDiscount, '￥') }}</div>
            <div v-if="scope.row.loanAmount > 0">药采贷{{ formatMoney(scope.row.loanAmount, '￥') }}</div>
            <div v-if="scope.row.quotaAmount > 0">账期{{ formatMoney(scope.row.quotaAmount, '￥') }}</div>
            <div v-if="scope.row.cashAmount > 0">现金{{ formatMoney(scope.row.cashAmount, '￥') }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="orderAmount"
          label="订单金额"
          width="120"
        >
          <template slot-scope="scope">
            <span>¥{{ scope.row.orderAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="exwarehouseAmount"
          label="出库金额"
          width="120"
        >
          <template slot-scope="scope">
            <span style="color:#26C487"
              >¥{{ scope.row.exwarehouseAmount }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="refundAmount"
          label="退款金额"
          width="120"
        >
          <template slot-scope="scope">
            <span style="color:#E33233">¥{{ scope.row.refundAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="checkAmount"
          label="成交金额"
          width="120"
        >
          <template slot-scope="scope">
            <span style="color:#26C487"> ¥{{ scope.row.checkAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="feeAmount"
          label="邮费"
          width="100"
        >
          <template slot-scope="scope">
            <span>¥{{ scope.row.feeAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="couponAmount"
          label="商家优惠"
          width="120"
        >
          <template slot-scope="scope">
            <span style="color:#FFA936">¥{{ scope.row.couponAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="80">
          <template slot-scope="scope">
            <div @click="goDetail(scope.row)" class="cup info">详情</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="total > 0" class="pagenation">
      <!-- <Pagination :total="total" /> -->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.pageNum"
        :page-size="queryForm.pageSize"
        layout="prev, pager, next, total,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import { Salesorder, orderReview, orderExport } from "@/request/MyReport";
import { getLabelByValue, formatMoney } from '@/utils/tool';
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      getLabelByValue,
      formatMoney,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        member: undefined,
        sn: undefined,
        erpOrderSource: undefined,
        payType: undefined,
        statisticsStartDate: undefined,
        statisticsEndDate: undefined,
      },
      //下单时间
      inputDate: [],
      total: 0,
      tableData: [],
      totalData: {
        checkAmount: 0,
        couponAmount: 0,
        goodsCount: 0,
        exwarehouseAmount: 0,
        feeAmount: 0,
        goodsAmount: 0,
        memberCount: 0,
        orderAmount: 0,
        orderCount: 0,
        refundAmount: 0,
      },
      orderSourceOptions: [
        {label: '集采', value: '20'},
        {label: '非集采', value: '10'},
      ],
      payOptions: [
        {label: '药采贷', value: '1'},
        {label: '慧金豆', value: '2'},
        {label: '账期', value: '3'},
        {label: '现金', value: '4'},
      ]
    };
  },
  created() {
    this.getReview();
    this.getList();
  },
  methods: {
    dateChange() {
      if (this.inputDate) {
        this.queryForm.statisticsStartDate = this.inputDate[0];
        this.queryForm.statisticsEndDate = this.inputDate[1];
      } else {
        this.queryForm.statisticsStartDate = undefined;
        this.queryForm.statisticsEndDate = undefined;
      }
      this.serchList();
    },
    getReview() {
      orderReview(this.queryForm).then((res) => {
        if (res.code == 200) {
          this.totalData = res.data;
        }
      });
    },
    //导出商品
    downloadFile() {
      orderExport(this.queryForm).then((result) => {
        if (result) {
          this.$message.success("正在下载当前列表");
        }
        const contentType = "application/octet-stream;charset=UTF-8";
        const blob = new Blob([result], { type: contentType });
        const objectURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectURL;
        a.download = "云集药业报表.xlsx"; // 此处的savedName为保存到本地的文件名称及后缀
        a.click();
      });
    },
    //订单详情
    goDetail(item) {
      this.$router.push({
        name: "OrderDetail",
        params: { id: item.id },
      });
    },
    getList() {
      Salesorder(this.queryForm).then((res) => {
        this.total = res.data.total || 0;
        this.tableData = res.data.rows;
      });
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "color: #333;";
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.getList();
    },
    //搜索
    serchList() {
      this.getReview();
      this.getList();
    },
    //重置
    resetFn() {
      this.queryForm.member = undefined;
      this.queryForm.sn = undefined;
      this.queryForm.statisticsEndDate = undefined;
      this.queryForm.statisticsStartDate = undefined;
      this.queryForm.erpOrderSource = undefined;
      this.queryForm.payType = undefined;
      this.queryForm.pageNum = 1;
      this.inputDate = [];
      this.serchList();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-select .el-input .el-select__caret {
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range-separator {
    padding: 0;
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range__icon {
    height: 32px;
  }

  .el-date-editor .el-range__close-icon {
    height: 32px;
  }

  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-button {
    height: 32px;
    padding: 7px 18px;
  }
}
.header {
  font-size: 14px;
  font-family: 'medium';
  font-weight: bold;
  color: #333333;
}
.all {
  .header {
    background: #f7f8fa;
    border: 1px solid #eaebf2;
    padding: 12px 20px;
    font-size: 14px;
    font-family: 'medium';
    font-weight: bold;
    color: #333333;
  }
  .mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1px solid #eaebf2;
    border-top: 0;
    margin-bottom: 25px;
  }
  .cont {
    display: flex;
    font-size: 14px;
    font-family: 'regular';
    font-weight: 400;
    color: #202d40;
    margin-bottom: 15px;
    .hui {
      margin-right: 10px;
      font-size: 14px;
      font-family: 'regular';
      font-weight: 400;
      color: #9e9fab;
    }
  }
}
.f14 {
  color: #333;
}
.tabel {
  width: 100%;
  max-width: 1106px;
}
.daifukuanhd {
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 0 20px;
  align-items: center;
  height: 42px;
  background: #f4fffb;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid rgba(38, 196, 135, 0.34);
  filter: blur(undefinedpx);
  font-size: 14px;
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.center {
  justify-content: center;
}
.info {
  color: #26c487;
  font-size: 14px;
}
.del {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.serch {
  flex: 1;
  text-align: right;
}
.serchtab {
  margin: 20px 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  .li {
    margin-right: 20px;
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    .serchName {
      font-size: 12px;
      font-family: 'regular';
      font-weight: 400;
      color: #333333;
      margin-right: 20px;
    }
    .ss {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .ipt {
      flex: 1;
    }
  }
}
.pagenation {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
}
</style>
