<template>
 <div class="Userinfo">
  账户信息
 </div>
</template>
 
<script>
export default {
 data () {
 return {

 }
 }
}
</script>
 
<style lang = "less" scoped>
 
</style>