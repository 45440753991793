/*
 * @Description: 
 * @version: 
 * @Author: chentianxiang
 * @Date: 2023-03-29 15:08:33
 * @LastEditors: 
 * @LastEditTime: 2023-12-22 10:00:26
 */
import request from '../request'
export function Salesorder (data){
    return request({
        url: `/report-v2/mall/sale/order`,
        method: 'POST',
        data
    })
  }
export function orderReview (data){
    return request({
        url: `/report-v2/mall/sale/orderReview`,
        method: 'POST',
        data
    })
  }
export function orderExport (data){
    return request({
        url: `/report-v2/mall/sale/orderExport`,
        method: 'POST',
        data,
        responseType: "blob",
    })
  }

// 账单报表列表
export function getBillReportAPI (data){
  return request({
    url: `/pay-v2/mall/pay/account/queryAccountDetailV2`,
    method: 'POST',
    data
  })
}
// 账单报表汇总信息
export function getBillTotalAPI (data){
  return request({
    url: `/pay-v2/mall/pay/account/queryAccountCountV2`,
    method: 'POST',
    data
  })
}