<template>
  <div class="box">
    <div class="box-title">我的账单</div>
    <el-form ref="form" :model="queryForm" label-width="80px" inline class="form">
      <el-form-item label="账户名称" prop="sn">
        <el-input v-model="queryForm.sn" clearable @keyup.native.enter="getList"/>
      </el-form-item>
      <el-form-item label="核算属性" prop="masterName">
        <el-input v-model="queryForm.masterName" clearable @keyup.native.enter="getList"/>
      </el-form-item>
      <el-form-item label="客户名称" prop="customerName">
        <el-input v-model="queryForm.customerName" clearable @keyup.native.enter="getList"/>
      </el-form-item>
      <el-form-item label="收入类型" prop="businessType" class="right">
        <el-select v-model="queryForm.businessType" clearable @change="getList">
          <el-option v-for="(item, index) in businessTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="银行类别" prop="bankType">
        <el-input v-model="queryForm.bankType" clearable @keyup.native.enter="getList"/>
      </el-form-item>
      <el-form-item label="收入/支出" prop="amountType">
        <el-select v-model="queryForm.amountType" clearable @change="getList">
          <el-option v-for="(item, index) in amountTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="主单号" prop="sn">
        <el-input v-model="queryForm.sn" clearable @keyup.native.enter="getList"/>
      </el-form-item>
      <el-form-item label="创建时间" class="right">
        <el-date-picker
          v-model="timeRange"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="right">
        <el-button type="success" size="medium" @click="getList">搜索</el-button>
        <el-button size="medium" @click="resetFun">重置</el-button>
      </el-form-item>
    </el-form>

    <div class="total-box">
      <div class="total-item">
        <img src="../../../assets/images/billReport/income.png" alt="">
        <div>收入(元)<span>{{ totalInfo.incomeAmount }}</span></div>
        <img src="../../../assets/images/billReport/back.png" alt="">
      </div>
      <div class="total-item total-item-green">
        <img src="../../../assets/images/billReport/pay.png" alt="">
        <div>支出(元)<span>{{ totalInfo.payAmount }}</span></div>
        <img src="../../../assets/images/billReport/back.png" alt="">
      </div>
    </div>

    <el-table :data="tableData" height="500px" :header-cell-style="{ background: '#F8F8F8' }" class="table">
      <el-table-column type="index" label="序号" width="50" align="center" />
      <el-table-column prop="accountName" label="账户名称" width="160" show-overflow-tooltip align="center"/>
      <el-table-column prop="masterCode" label="核算属性" width="120" show-overflow-tooltip align="center"/>
      <el-table-column label="收入类型" align="center" key="businessType" width="140">
          <template slot-scope="scope">
            <div>{{ getLabelByValue(scope.row.businessType, businessTypeList) }}</div>
          </template>
        </el-table-column>
      <el-table-column prop="bankType" label="银行类别" width="120" show-overflow-tooltip align="center"/>
<!--      <el-table-column prop="modifyAmount" label="期初余额(元)" width="120" show-overflow-tooltip align="center"/>-->
      <el-table-column prop="borrowerAmount" label="收入(元)" width="100" show-overflow-tooltip align="center"/>
      <el-table-column prop="lenderAmount" label="支出(元)" width="140" show-overflow-tooltip align="center"/>
<!--      <el-table-column label="期末余额(元)" align="center" key="resultAmount" prop="resultAmount" width="120" show-overflow-tooltip/>-->
      <el-table-column label="主单号" align="center" key="sn" prop="sn" width="180" show-overflow-tooltip/>
      <el-table-column label="单据类型" align="center" key="documentType" width="120">
        <template slot-scope="scope">
          <div>{{ getLabelByValue(scope.row.documentType, documentTypeList) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="创建时间" width="160" show-overflow-tooltip align="center"/>
      <el-table-column prop="date" label="操作" width="120" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.documentType === 20"
            size="medium"
            type="text"
            @click="checkOrder(scope.row)"
            >查看订单</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="queryForm.pageNum"
      :page-size="queryForm.pageSize"
      class="pagination"
      background
      layout="prev, pager, next, total"
      :total="total"
      @current-change="pageChange">
    </el-pagination>
  </div>
</template>

<script>
import { getBillReportAPI, getBillTotalAPI } from '../../../request/MyReport/index'
import { getLabelByValue } from '../../../utils/tool'
import jsonData from './data.json'
export default {
  data() {
    return {
      getLabelByValue,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        masterName: undefined,
        sn: undefined,
        customerName: undefined,
        businessType: undefined,
        amountType: undefined,
        bankType: undefined
      },
      total: 0,
      timeRange: [],
      tableData: [],
      totalInfo: {},
      businessTypeList: jsonData.businessType,
      documentTypeList: jsonData.documentType,
      amountTypeList: jsonData.amountType,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getTotal() {
      getBillTotalAPI(this.queryForm).then(res => {
        if (res.code === 200) {
          this.totalInfo = res.data
        }
      })
    },
    getList() {
      getBillReportAPI(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.rows;
          this.total = res.data.total;
          this.getTotal();
        }
      })
    },
    pageChange(e) {
      this.queryForm.pageNum = e;
      this.getList();
    },
    resetFun() {
      this.$refs.form.resetFields();
      this.pageNum = 1;
      this.getList();
    },
    checkOrder(item) {
      this.$router.push({
        name: "MyOrder",
        params: { sn: item.sn},
      });
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 30px;
}
.box-title {
  height: 18px;
  font-size: 18px;
  font-family: 'regular';
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  border-left: 5px solid #26C487;
  padding-left: 10px;
}

.form {
  margin: 10px 0 50px;
  ::v-deep .el-form-item__label {
    font-size: 12px;
  }
  .el-form-item {
    width: 24%;
    margin-bottom: 10px;
  }
  ::v-deep .el-form-item__content {
    width: 180px;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-date-editor--daterange.el-input__inner {
      width: 180px;
    }
  }

  .right {
    float: right;
    text-align: right;
    margin-right: 0;
  }
}

.total-box {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #ffffff;
  .total-item {
    width: 546px;
    height: 72px;
    background: linear-gradient(123deg, #FFCB51 0%, #FF9C1E 100%);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding-left: 17px;
    div {
      flex: 1;
      margin-left: 10px;
    }
    span {
      font-size: 26px;
      font-family: 'medium';
      margin-left: 10px;
    }
  }
  .total-item-green {
    background: linear-gradient(325deg, #26C487 0%, #56DCA9 100%);
  }
}

.table {
  width: 1106px;
  margin: 20px 0;
}

.pagination {
  text-align: right;
}
</style>
