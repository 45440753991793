<template>
  <div class="Preferential">
    <div class="top">
      <li class="left">
        <div class="left-title">
          慧金豆余额
          <el-popover
            placement="right-start"
            width="200"
            trigger="click"
            content="支付时如选择慧金豆，即会锁定对应慧金豆金额；如取消支付即释放对应慧金豆金额；支付成功即扣除对应慧金豆金额。">
            <i class="el-icon-question icon" slot="reference"></i>
          </el-popover>
        </div>
        <CustomPrice
          :value="statistics.huiCoinAmount"
          color="#26C487"
          fontSize="28px"
          :fontWeight="600"
        ></CustomPrice>
        <div class="lock">（锁定金额 <span>¥{{ statistics.huiCoinLockAmount }}</span>）</div>
        <el-button
          v-if="isLoan"
          @click="gorecharge"
          size="small"
          class="mt10"
          type="success"
          >充值</el-button
        >
      </li>
      <div class="su"></div>
      <li class="right">
        <div class="li">
          <div class="hui">充值总金额</div>
          <div class="value">
            {{ statistics.rechargeHuiCoinAmount || 0 }}
            <span class="unit">元</span>
          </div>
        </div>
        <div class="li">
          <div class="hui">抵扣总金额</div>
          <div class="value">
            {{ statistics.discountHuiCoinAmount || 0 }}
            <span class="unit">元</span>
          </div>
        </div>

        <div class="li">
          <div class="hui">充值涉及订单数</div>
          <div class="value">
            {{ statistics.rechargeOrders || 0 }}
            <span class="unit">单</span>
          </div>
        </div>

        <div class="li">
          <div class="hui">抵扣涉及订单数</div>
          <div class="value">
            {{ statistics.discountOrders || 0 }}
            <span class="unit">单</span>
          </div>
        </div>
      </li>
      <!-- 

        <div class="li">
          <div class="hui">退还总金额</div>
          <div class="value">
            {{statistics.withdrawTotalAmount || 0}}
            <span class="unit">元</span>
          </div>
        </div>
 
   
        <div class="li">
          <div class="hui">退还涉及订单数</div>
          <div class="value">
            {{statistics.withdrawOrders || 0}}
            <span class="unit">单</span>
          </div>
      </div>-->
    </div>
    <div class="mid"></div>
    <div class="footer" style="padding: 40px 32px">
      <div class="nav-header">
        <div @click="changeFn(1)" :class="active === 1 ? 'nav active' : 'nav'">
          慧金豆明细
        </div>
        <div v-if="isLoan" @click="changeFn(2)" :class="active === 2 ? 'nav active' : 'nav'">
          充值单明细
        </div>
      </div>

      <el-form v-show="active === 1" class="icon-el-form" :model="searchForm" ref="huiQueryForm" size="small" inline label-width="80px">
        <el-form-item label="日期" prop="dateTime">
          <el-date-picker
            v-model="dateTime"
            align="right"
            type="daterange"
            unlink-panels
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            @change="onChangeTimeRange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="银行" prop="bankType" class="center">
          <el-input
            v-model="searchForm.bankType"
            placeholder="请输入银行"
            clearable
            maxlength="20"
            @keyup.enter.native="fetchList"
          />
        </el-form-item>
        <el-form-item label="客户名称" prop="customerName" class="center">
          <el-input
            v-model="searchForm.customerName"
            placeholder="请输入客户名称"
            clearable
            maxlength="20"
            @keyup.enter.native="fetchList"
          />
        </el-form-item>
        <el-form-item label="单据号" prop="paySn"  class="icon-right">
          <el-input
            v-model="searchForm.paySn"
            placeholder="请输入单据号"
            clearable
            maxlength="20"
            @keyup.enter.native="fetchList"
          />
        </el-form-item>
        <el-form-item label="类型" prop="sourceTypes">
          <el-select v-model="searchForm.sourceTypes" placeholder="请选择" clearable multiple collapse-tags>
            <el-option v-for="(item, index) in optionsSource" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收入/支出" prop="amountType" class="center">
          <el-select v-model="searchForm.amountType" placeholder="请选择" clearable @change="fetchList">
            <el-option v-for="item in optionsType" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="icon-right">
          <el-button type="success" size="medium" @click="fetchList"
            >搜索</el-button
          >
          <el-button size="medium" @click="resetSearchForm">重置</el-button>
        </el-form-item>
      </el-form>
      <div v-show="active === 1" class="total-box">
        <div class="total-item">
          <img src="../../../assets/images/accountPeriod/iconIncome.png" alt="" />
          <div class="total-info">收入<span>{{ iconTotalInfo.incomeAmountCount }}</span>元</div>
          <img src="../../../assets/images/accountPeriod/iconBack.png" alt="" />
        </div>
        <div class="total-item total-item-yellow">
          <img src="../../../assets/images/accountPeriod/iconExpend.png" alt="" />
          <div class="total-info">支出<span>{{ iconTotalInfo.payAmountCount }}</span>元</div>
          <img src="../../../assets/images/accountPeriod/iconBack.png" alt="" />
        </div>
      </div>

      <el-row v-show="active === 2">
        <el-col :span="8">
          充值状态&nbsp;
          <el-select
            v-model="rechargeFrom.type"
            placeholder
            class="select mb20"
            popper-class="select-pop"
            @change="getRechargeList"
          >
            <el-option
              v-for="item in rechargeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option> </el-select
        ></el-col>
        <el-col :span="8">
          充值周期&nbsp;
          <el-select
            v-model="rechargeFrom.cycle"
            placeholder
            class="select"
            popper-class="select-pop"
            @change="getRechargeList"
          >
            <el-option
              v-for="item in rechargeCycleOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option> </el-select
        ></el-col>

        <el-col :span="8">
          <div class="flex-end mb20">
            申请充值时间&nbsp;
            <el-date-picker
              v-model="rechargeDateTime"
              class="date"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="getRechargeList"
            ></el-date-picker>
          </div>
        </el-col>

        <el-col :span="24">
          <div class="flex-end">
            <el-button @click="getRechargeList" type="success">搜索</el-button>
            <el-button @click="resetFn">重置</el-button>
          </div>
        </el-col>
      </el-row>

      <div class="List mt30">
        <el-table
          v-show="active === 1"
          :cell-style="cellStyle"
          :header-cell-style="{ background: '#F8F8F8' }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            prop="createTime"
            label="日期"
            width="auto"
          ></el-table-column>
          <el-table-column
            prop="sourceType"
            align="center"
            label="类型"
            width="120"
          >
            <template slot-scope="scope">
              <span>{{ filterSourceType(scope.row.sourceType) }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="收入/支出">
            <template slot-scope="sc">
              <CustomPrice
                :value="sc.row.amountDetail"
                :color="sc.row.amountType == 1 ? '#e33233' : '#26c487'"
                fontSize="14px"
                :fontWeight="400"
              ></CustomPrice>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="单据号"
            width="auto"
          >
            <template slot-scope="scope">
              {{ getSnLabel(scope.row) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="customerName"
            align="center"
            label="客户"
            width="auto"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.customerName || "--" }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="180">
            <template slot-scope="sc">
              <span
                v-if="sc.row.sourceType === 'DEDUCT' || sc.row.sourceType === 'ADJUUSTPRICE' || sc.row.sourceType === 'BALANCEPAYMENT' || sc.row.sourceType === 'WITHDRAW' || sc.row.sourceType === 'SALESRETURN'"
                @click="handleViewOrderDetail(sc.row)"
                class="gr cup fs14"
                style=""
              >订单列表</span>
              <span
                v-if="sc.row.sourceType === 'BALANCEPAYMENT' || sc.row.sourceType === 'ADJUUSTPRICE' || sc.row.sourceType === 'REFUNDPRODUCT' || sc.row.sourceType === 'DISCOUNTSALLOWANCES' || sc.row.sourceType === 'SALESRETURN'"
                @click="handleAfterViewOrderDetail(sc.row)"
                class="gr cup fs14"
              >
                售后详情
            </span>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-show="active === 2"
          :header-cell-style="{ background: '#F8F8F8' }"
          :data="detailList"
          style="width: 100%"
        >
          <el-table-column
            prop="rechargeDate"
            label="申请充值时间"
            align="center"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="rechargeNo"
            :show-overflow-tooltip="true"
            label="充值单编号"
            align="center"
            width="auto"
          ></el-table-column>
          <el-table-column
            prop="rechargeCycle"
            align="center"
            label="充值周期"
            width="auto"
          ></el-table-column>
          <el-table-column
            prop="rechargeAmount"
            align="center"
            label="充值金额"
            width="auto"
          ></el-table-column>
          <el-table-column
            prop="rechargeStatus"
            align="center"
            label="充值状态"
            width="auto"
          >
            <template slot-scope="scope">
              <span>{{ filterRechargeStatus(scope.row.rechargeStatus) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="refundAmount"
            align="center"
            label="操作"
            width="auto"
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row.rechargeStatus === 110"
                style="color: #26c487; font-size: 14px"
                class="cup"
                @click="checkpayFn(scope.row)"
              >
                查看支付二维码
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="tableData.length" class="pagination flex-end">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="searchForm.pageNum"
        :page-size="20"
        layout="prev, pager, next, total"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 明细 -->
    <el-dialog
      title="明细"
      :visible.sync="visibleOrderDetail"
      width="50%"
      class="dia"
    >
      <div class="List mt30">
        <el-table
          :header-cell-style="{ background: '#F8F8F8' }"
          :data="mallOrders"
          style="width: 100%"
        >
          <el-table-column
            prop="orderSn"
            label="订单号"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="productSn"
            align="center"
            label="商品编号"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="productName"
            label="商品名称"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="refundProductQuantity"
            align="center"
            label="数量"
            width="auto"
          ></el-table-column>
          <el-table-column
            prop="salesPrice"
            align="center"
            label="单价"
            width="auto"
          ></el-table-column>
          <el-table-column
            prop="refundAmount"
            align="center"
            label="小计(元)"
            width="auto"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="ruleDialog" title="充值规则" width="600px">
      <div class="ruleDialog-box1">
        <div class="green">
          注：某客户药采贷总授信额度/周转时间=单月平均充值金额(简记为“A”)
        </div>
        <div class="h">
          <div class="h4">对所有客户：</div>
          <div>在充值周期内最多可充值一次,也可以不充值。</div>
        </div>

        <div class="h">
          <div class="h4">对于存量客户：</div>
          <div>会先整体初始化一次充值金额,具体操作如下：</div>
          <div>
            1.假定该客户已开通药采贷的月份数为X,使用授信额度为Y,最近一次课充值金额为：
          </div>
          <div>&nbsp; 当(A*X)>=Y, 充值金额为A或小于A</div>
          <div>&nbsp; 当(A*X){{ "<" }}Y,充值金额为(Y- (A*X) + A)</div>
        </div>

        <div class="h">
          <div class="h4">对于新客户：</div>
          <div>不管某个月份是否充值,后续月份的充值金额固定为A或小于A。</div>
        </div>
        <div class="h">
          <div class="h4">其他：</div>
          <div>
            1.如果存量客户当前月份已用过药采贷支付(支付成功才算) ,
            则本月不计入剩余到期月份的计算。
          </div>
          <div>2.如果客户中途提前还款,则充值金额固定为A或小于A。</div>
          <div>
            3.A保留整数(小数点部分向下取整) ,
            且如果授信时间内可以等到最后一个月,则除不尽的金额都累加到这个月;如果超出授信时间,则最后一次可充值金额仍然为A。
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ruleDialog = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 慧金豆充值弹窗-->
    <el-dialog :visible.sync="rechargeDialog" title="充值" width="500px" :close-on-click-modal="false">
      <div class="recharge-notice">
        <img src="../../../assets//images/cart/pay-notice-icon.png" alt="">
        <span>实际可用额度以银行最终放款为准</span>
      </div>
      <div class="recharge-box">
        <div @click="ruleDialog = true" class=" cup flex-end mb20 aic gr a-right">
          <i class="el-icon-question  gr ml5"></i>充值规则
        </div>
        <div class="line">当月预计总充值金额：{{formatMoney(rechargeData.totalAmt,'￥') }}</div>
        <div class="line">当月剩余可充值金额：{{formatMoney(rechargeData.amount,'￥')}}</div>
        <div class="line">当月充值时效：{{rechargeData.startDate}} ~ {{rechargeData.endDate}}</div>
        <div class="line">本次充值金额：￥<el-input v-model="rechargePayAmt" placeholder="请输入充值金额" clearable/></div>
      </div>
      

      <span slot="footer" class="dialog-footer">
        <el-button class="gary" type="text" @click="rechargeDialog = false">取消充值</el-button>
        <el-button type="primary" @click="goPayFn" :disabled="!Boolean(rechargePayAmt)">去充值</el-button>
      </span>
    </el-dialog>

    <!-- 微信支付弹框 -->
    <div class="paylog">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="buy_code"
        width="430px"
        @close="buy_close"
      >
        <img src="@/assets/images/quicklyOrder/payIMG.png" alt="" />
        <div style="display: flex;justify-content: center;position: absolute;top: 30px;right: 0;transform: translate(-60px, 165px);"
          class="buy_box dis_f_c_c"
        >
          <div id="qrcode" ref="qrcode" class="img1 dis_f_c_c"></div>
        </div>
        <div
          @click="buy_close"
          style="margin-top: 40px"
          class="cup flex-center"
        >
          <img src="@/assets/images/quicklyOrder/Close.png" alt="" />
        </div>
      </el-dialog>
    </div>

    <!-- 订单列表弹窗-->
    <el-drawer
      title="订单列表"
      :visible.sync="showOrderList"
      size="800px">
      <div class="drawer-pay">
        <div class="title">
          <div class="title-icon"></div>
          <div>支付单信息</div>
        </div>
        <div class="pay-info">
          <div>
            <span class="info-top">支付单编号：{{payDeatail.paySn}}</span>
            <span class="info-top">支付时间：{{payDeatail.payTime}}</span>
          </div>
          <div>
            支付方式：<span v-show="payDeatail.hjdAmount > 0">慧金豆抵扣（{{formatMoney(payDeatail.hjdAmount,'￥')}}）</span> 
            <span v-show="payDeatail.hjdAmount > 0 && payDeatail.loanAmount > 0"> / </span>
            <span v-show="payDeatail.loanAmount > 0">药采贷（{{formatMoney(payDeatail.loanAmount,'￥')}}）</span> 
            <span v-show="payDeatail.quotaAmount > 0 && payDeatail.loanAmount > 0"> / </span>
            <span v-show="payDeatail.quotaAmount > 0">账期（{{formatMoney(payDeatail.quotaAmount,'￥')}}）</span> 
            <span v-show="payDeatail.quotaAmount > 0 && payDeatail.cashAmount > 0"> / </span>
            <span v-show="payDeatail.cashAmount > 0">现金（{{formatMoney(payDeatail.cashAmount,'￥')}}）</span>
          </div>
        </div>
      </div>
      <div class="drawer-pay" style="height:700px;overflow: auto;">
        <div class="title">
          <div class="title-icon"></div>
          <div>订单列表</div>
        </div>
        <div class="have">
          <div class="li" v-for="(item, index) in orderList" :key="index" style="margin-top:20px;">
            <div class="head df">
              <div style="width: 100%" class="content">
                <div class="bg"></div>
                <li>
                  <div class="cld realpay">
                    {{ item.order.status > 110 ? "实" : "应" }}付总金额：
                    <span style="color:#E33233">¥{{ item.order.status > 100 ? item.order.payAmount : item.order.totalAmount }}</span>
                  </div>
                  <div class="su"></div>
                  <div class="cld">
                    订单号：
                    <span>{{ item.order.orderSn }}</span>
                  </div>
                  <div class="su"></div>

                  <div class="cld">
                    下单时间：
                    <span>{{ item.order.createTime }}</span>
                  </div>
                  <div
                    class="cld"
                    style="color: #26c487; flex: 1; justify-content: flex-end"
                  >
                    <!-- 待付款状态 //订单状态：-1全部 待付款-100 支付中-110 待发货-120 部分发货-130 待收货-140 部分收货-150   已完成-160 已关闭-170-->
                    <div v-if="item.order.status == 100" class="daifukuan">
                      <span>待付款</span>
                    </div>
                    <div v-if="item.order.status == 110" class="daifukuan">
                      <span>支付中</span>
                    </div>
                    <div v-if="item.order.status == 140" class="daishouhuo">
                      待收货
                    </div>
                    <div v-if="item.order.status == 120" class="daifahuo">
                      待发货
                    </div>
                    <div v-if="item.order.status == 160" class="daishouhuo">
                      已完成
                    </div>
                    <div v-if="item.order.status == 170" class="yiguanbi">
                      已关闭
                    </div>
                  </div>
                </li>
                <li>
                  <div class="cld">
                    客户：
                    <span>{{ item.order.customerName }}</span>
                  </div>
                  <div class="su"></div>
                  <div class="cld">
                    收货方式：
                    <span>
                      {{
                        item.order.receiveType == 1 ? "送货上门" : "到仓自提"
                      }}
                    </span>
                  </div>
                </li>
                <li class="df flex-between">
                  <div class="cld">
                    <div>
                      {{
                        item.order.receiveType == 1 ? "收货人" : "提货人"
                      }}信息：
                    </div>

                    <span>
                      {{ item.order.takeName || item.order.receiveName }}
                      {{ item.order.takePhone || item.order.receivePhone }}
                      {{ item.order.receiveAddress }}
                    </span>
                  </div>
                  <div
                    v-if="item.endtime && item.order.status == 100"
                    style="display: flex;align-items: center;justify-content: flex-end;"
                    class="fred tar"
                  >
                    <img
                      style="margin-right: 5px"
                      class="icon"
                      src="@/assets/images/person/warning.png"
                      alt
                    />
                    剩余付款时间： {{ item.endtime }}
                  </div>
                </li>
              </div>
            </div>
            <div>
              <div class="cardlist">
                <div v-for="(it, idx) in item.orderItems" :key="idx">
                  <div v-if="idx < 2">
                    <div class="bigl">
                      <Product-Card :isMyorder="true" :rows="it">
                      </Product-Card>
                    </div>
                  </div>
                </div>
                <div v-if="item.totalNum - 2 > 0" class="su"></div>
                <div v-if="item.totalNum - 2 > 0" class="listNum">
                  <div class="num nwp">
                    剩余{{
                      item.totalNum - 2 > 0 ? item.totalNum - 2 : 0
                    }}类商品
                  </div>
                </div>

                <div v-if="item.totalNum - 2 > 0" class="su"></div>
                <div class="listNum">
                  <div
                    v-if="item.totalNum <= 2"
                    style="margin-right:20px"
                    class="su"
                  ></div>
                  <div
                    v-if="
                      item.order.status == 100 || item.order.status == 110
                    "
                    class="caozuo"
                  >
                    <div @click="goDetail(item)" class="xiangqing cup">
                      查看详情
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        class="pagenation"
        @size-change="getOrderList"
        @current-change="getOrderList"
        :current-page.sync="listQuery.pageNum"
        :page-sizes="[10]"
        layout="total, sizes, prev, pager, next"
        :total="listTotal"
      >
      </el-pagination>
    </el-drawer>
  </div>
</template>

<script>
import { info } from "@/request/users/users";
import axios from "axios";
import QRCode from "qrcodejs2"; // 引入qrcode,转二维码
import CustomPrice from "@/components/custom-base/custom-price";
import {
  apiGetPreferentialList,
  apiGetPreferentialStatistics,
  rechargeDetailListApi,
  getPayInfoApi,
  coinOrderPayApi,
  getOrderListByCoin,
  getCoinCount
} from "@/request/preferential/index";
import { applyRefundDetail } from '@/request/Orders/order'
import dayjs from "dayjs";
import {formatMoney,numberRegFun} from '@/utils/tool'
import ProductCard from "@/components/custom-base/custom-product-card";
export default {
  components: {
    CustomPrice,
    ProductCard
  },
  data() {
    return {
      active: 1,
      //用户对公对私权限
      Paycom: localStorage.getItem("Paycom") || false,
      //药采贷权限
      isLoan: localStorage.getItem("isloan") || false,
      //gps
      location: "",
      rechargeFrom: {
        cycle: null,
        type: "0",
      },
      detailList: [],
      rechargeDateTime: [],
      rechargeDialog: false,
      ruleDialog: false,
      visibleOrderDetail: false,
      total: 10,
      rechargeData: {
        totalAmt:0,
        amount:0,
        orderId:null,
      },
      rechargePayAmt:0,
      dateTime: [],
      searchForm: {
        customerAddrId: this.$store.state.AddrId,
        pageNum: 1,
        pageSize: 20,
        sourceTypes: [],
        amountType: undefined,
        startDate: "",
        endDate: "",
        bankType: undefined,
        customerName: undefined,
        paySn: undefined
      },
      statistics: {},
      iconTotalInfo: {},
      currentTime: 0,
      buy_code: false,
      rechargeOption: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "110",
          label: "充值中",
        },
        {
          value: "140",
          label: "充值成功",
        },
        {
          value: "180",
          label: "充值失败",
        },
      ],
      rechargeCycleOption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "MONTH",
          label: "按月",
        },
        {
          value: "UNSETTER",
          label: "未设置",
        },
      ],
      optionsSource: [
        {
          value: "RECHARGE",
          label: "充值",
        },
        {
          value: 'DEDUCT',
          label: "抵扣",
        },
        {
          value: 'WITHDRAW',
          label: "退还",
        },
        {
          value: "ADJUST",
          label: "余额调整",
        },
        {
          value: "REGISTER_NEW",
          label: "拉新注册",
        },
        {
          value: "LOAN",
          label: "药采贷贴息",
        },
        {
          value: "REFUNDPRODUCT",
          label: "退货",
        },
        {
          value: "BALANCEPAYMENT",
          label: "补差",
        },
        {
          value: "ADJUUSTPRICE",
          label: "调价",
        },
        {
          value: "DISCOUNTSALLOWANCES",
          label: "折让",
        },
        {
          value: 'RECALL',
          label: "召回",
        },
        {
          value: 'SALESRETURN',
          label: "销退",
        },
      ],
      optionsType: [
        {
          value: 2,
          label: "收入",
        },
        {
          value: 1,
          label: "支出",
        },
      ],
      tableData: [],
      mallOrders: [],
      userInfo: JSON.parse(localStorage.getItem("customerList")),
      formatMoney:formatMoney,

      showOrderList:false,
      orderList:[],
      listQuery:{
        isCorporate: localStorage.getItem("isloan") ? true : false, //是否是法人
        customerAddrId: this.$store.state.AddrId, //客户地址id
        pageSize:10,
        pageNum:1,
        status:'-1',
        paySn:null
      },
      listTotal:0,
      payDeatail:{
        paySn:null,
        payTime:null,
        hjdAmount:null,
        loanAmount:null,
        quotaAmount:null,
        cashAmount:null
      },

      pickerOptions: {
        shortcuts: [{
          text: '近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近一月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近三月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '近半年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  mounted() {
    axios({
      url: `https://restapi.amap.com/v3/ip?key=d369aeea0b01d0c9a5c4b0fac64127ad`,
    }).then((res) => {
      if (res.status == 200) {
        const loc = res.data.rectangle.split(";")[0].split(",");
        this.location =
          (loc && loc[1] ? loc[1] : "30.53006918") +
          "-" +
          (loc && loc[0] ? loc[0] : "103.9017713");
      }
    });
  },
  created() {
    this.getUserInfo();
    this.fetchList();
    this.fetchStatistics();
  },
  /* watch: {
    searchForm: {
      handler(n, o) {
        this.fetchList();
      },
      deep: true,
    },
  }, */
  methods: {
    async gorecharge() {
      let req = {
        customerId: this.userInfo.id,
        pageNum: 1,
        pageSize: 12,
      };
      const { code, data } = await getPayInfoApi(req);
      if (code === 200) {
        this.rechargeData = data;
        this.rechargePayAmt = this.rechargeData.amount
        this.rechargeDialog = true;
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "color: #333";
    },
    changeFn(type) {
      this.active = type;
      this.pageNum = 1;
      this.pageSize = 10;

      type === 1 ? this.fetchList() : this.getRechargeList();
    },
    async getRechargeList() {
      let req = {
        ...this.rechargeFrom,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        endDate: this.rechargeDateTime ? this.rechargeDateTime[1] : null,
        startDate: this.rechargeDateTime ? this.rechargeDateTime[0] : null,
      };
      const { code, data } = await rechargeDetailListApi(req);
      if (code === 200) {
        this.detailList = data.rows;
        this.total = data.total;
      }
    },
    //重置
    resetFn() {
      this.rechargeDateTime = [];
      this.pageNum = 1;
      this.rechargeFrom = {
        cycle: null,
        type: "0",
      };
      this.getRechargeList();
    },
    getIconTotal() {
      getCoinCount(this.searchForm).then(res => {
        if (res.code === 200) {
          this.iconTotalInfo = res.data
        }
      })
    },
    async fetchList() {
      const {
        code,
        data: { rows = [], total = 0 },
      } = await apiGetPreferentialList(this.searchForm);
      if (code === 200) {
        this.tableData = rows;
        this.total = total;
      }
      this.getIconTotal();
    },
    resetSearchForm() {
      this.$refs.huiQueryForm.resetFields();
      this.searchForm.startDate = '';
      this.searchForm.endDate = '';
      this.dateTime = [];
      this.fetchList();
    },
    async fetchStatistics() {
      const { data, code } = await apiGetPreferentialStatistics({
        customerAddrId: this.$store.state.AddrId,
      });
      if (code === 200) {
        this.statistics = data;
      }
    },
    handleChangeDate(value) {
      if (value === 0) {
        this.dateTime = ["", ""];
        this.searchForm.startDate = "";
        this.searchForm.endDate = "";
        return;
      }
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * value);
      this.dateTime = [start, end];
      this.searchForm.startDate = dayjs(start).format("YYYY-MM-DD");
      this.searchForm.endDate = dayjs(end).format("YYYY-MM-DD");
    },
    
    onChangeTimeRange(e) {
      this.currentTime = -1;
      if (!e) {
        this.currentTime = 0;
        e = ["", ""];
      }
      this.searchForm.startDate = e[0];
      this.searchForm.endDate = e[1];
    },
    handleCurrentChange(value) {
      this.searchForm.pageNum = value;
      this.fetchList();
    },
    filterSourceType(value) {
      return (
        this.optionsSource.filter((item) => item.value === value)[0]?.label ||
        ""
      );
    },
    filterRechargeStatus(value) {
      return (
        this.rechargeOption.filter((item) => item.value == value)[0]?.label ||
        ""
      );
    },
    handleViewOrderDetail(item) {
      this.listQuery.paySn = item.paySn ? item.paySn : item.orderSn;
      this.getOrderList();
    },
    // 跳转售后详情
    handleAfterViewOrderDetail(item) {
      applyRefundDetail({ applyId: item.applyId }).then((res) => {
        if (res.code === 200) {
          let data = { ...res.data, id: item.id };
          this.$router.push({
            name: `AfterseleDetail`,
            params: data,
          });
        }
      });
    },
    //  获取订单列表
    getOrderList(){
      getOrderListByCoin(this.listQuery).then(res => {
        if(res.code == 200){
          this.orderList = res.data.itemList.rows
          this.listTotal = res.data.itemList.total
          this.payDeatail.paySn = res.data.paySn
          this.payDeatail.payTime = res.data.payTime
          this.payDeatail.hjdAmount = res.data.hjdAmount
          this.payDeatail.loanAmount = res.data.loanAmount
          this.payDeatail.quotaAmount = res.data.quotaAmount
          this.payDeatail.cashAmount = res.data.cashAmount
          this.showOrderList = true
        }
      })
    },
    goPayFn() {
      if(!numberRegFun(this.rechargePayAmt)){
        this.$message.warning('请输入正确充值金额')
        return
      }
      if(this.rechargePayAmt == 0){
        this.$message.warning('充值金额不能为0')
        return
      }
      if(this.rechargePayAmt > this.rechargeData.amount){
        this.$message.warning('本次充值金额已超上限，请调整后再充值')
        return
      }

      this.rechargeDialog = false;
      let req = {
        bizCd: "",
        createOdr: 0,
        entFlag: localStorage.getItem("Paycom") ? 1 : 0, //对私0对公1
        gps: this.location,
        odrIds: [this.rechargeData.orderId],
        paymentType: 110,
        wxDevice: 0,
        payAmt:this.rechargePayAmt
      };
      coinOrderPayApi(req)
        .then((res) => {
          if (
            res.code == 200 &&
            this.paymentType == 110 &&
            localStorage.getItem("Paycom")
          ) {
            this.$message.success("已提交对公支付");
            return;
          }
          if (res.data.creditFullPay == 0 && res.code == 200) {
            if (res.data.payUrl) {
              this.buy_code = true;
              this.$nextTick(() => {
                this.qrcode = new QRCode(this.$refs.qrcode, {
                  width: 300, //二维码宽度
                  height: 300, //二维码高度
                  text: res.data.payUrl, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr="
                  colorDark: "#000", //颜色配置
                  colorLight: "#fff",
                });
              });
            }
            //循环请求查看是否支付成功
            this.timer1 = setInterval(() => {
              this.wx_time(res.data.paySn);
            }, 1000);

            //使用nextTick确保在弹框显示前二维码数据渲染，不加的话可能报错获取不到qrcode元素
          } else if (res.data.firmCertType == 1) {
            this.$message.success("正在支付中");
            setTimeout(() => {
              this.$router.push("/home");
            }, 1000);
          } else if (res.data.creditFullPay == 1) {
            this.$message.success("支付成功");
            setTimeout(() => {
              this.$router.push("/home");
            }, 1000);
          } else if (res.code !== 200) {
            this.buy_code = false;
          }
          this.buyLoading = false;
        })
        .catch(() => {
          this.buyLoading = false;
        });
    },

    buy_close() {
      this.buy_code = false;
      this.$refs.qrcode.innerHTML = "";
    },
    //查看支付二维码
    checkpayFn(item) {
      if (!item.url) {
        this.$message.warning("二维码获取失败，请联系业务员");
        return;
      }
      this.buy_code = true;
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrcode, {
          width: 300, //二维码宽度
          height: 300, //二维码高度
          text: item.url, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr="
          colorDark: "#000", //颜色配置
          colorLight: "#fff",
        });
      });
      //循环请求查看是否支付成功
      this.timer1 = setInterval(() => {
        this.wx_time(item.paySn);
      }, 1000);
    },
    //监听是否支付成功
    wx_time(paySn) {
      if (this.buy_code == false) {
        this.$refs.qrcode.innerHTML = "";
        //关闭弹窗后
        clearInterval(this.timer1);

        return;
      }
      findDetailByPaySn({ paySn: paySn }).then((res) => {
        if (res.code == 200) {
          if (res.data.status == 140) {
            this.paysuccess = true;
            //监听支付成功后
            //成功后关闭定时器
            clearInterval(this.timer1);
            this.$refs.qrcode.innerHTML = ""; //清空渲染的二维码
            this.buy_code = false; //关闭弹窗
            this.$message({
              type: "success",
              message: "支付成功",
            });
            type === 1 ? this.fetchList() : this.getRechargeList();
          }
        } else {
          //成功后关闭定时器
          clearInterval(this.timer1);
          this.$refs.qrcode.innerHTML = ""; //清空渲染的二维码
          this.buy_code = false; //关闭弹窗
        }
      });
    },
    //再次获取用户权限信息
    getUserInfo() {
      info().then((res) => {
        if (res.code === 200) {
          //药采贷权限
          if (res.data.permissions.indexOf("mall:pay:loan") !== -1) {
            localStorage.setItem("isloan", "true");
          } else {
            localStorage.removeItem("isloan");
          }
          //对公支付
          if (res.data.permissions.indexOf("mall:pay:com") !== -1) {
            localStorage.setItem("Paycom", "true");
            this.Paycom = true;
          } else {
            this.Paycom = false;
            localStorage.removeItem("Paycom");
          }
        }
      });
    },

    //  查看详情
    goDetail(item){
      this.$router.push({
        name: "OrderDetail",
        params: { id: item.orderId },
      });
    },

    getSnLabel(item) {
      let label;
      if (item.sourceType === 'RECHARGE' || item.sourceType === 'ADJUST') {
        label = item.rechargeSn
      } else if (item.sourceType === 'BALANCEPAYMENT' || item.sourceType === 'ADJUUSTPRICE' || item.sourceType === 'WITHDRAW' || item.sourceType === 'SALESRETURN') {
        label = item.orderSn
      } else {
        label = item.paySn
      }
      return label
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
.disabel {
  cursor: not-allowed;
  color: #adafad;
}
::v-deep {
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 260px;
  }
  .recharge-card {
    margin-bottom: 10px;
    padding: 20px 25px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    border: 1px solid #bbbccb;
  }
  .recharge-card-active {
    border: 1px solid #26c487;
  }

  .ml5 {
    margin-left: 5px;
  }
  .aic {
    align-items: center;
  }
  .mb25 {
    margin-bottom: 25px;
  }
  .nav-header {
    display: flex;
    margin-bottom: 30px;
    .nav {
      font-size: 14px;
      cursor: pointer;
      margin-right: 26px;
      display: flex;
      align-items: center;
      padding: 0 18px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      border-bottom: 4px solid transparent;
    }
    .active {
      color: #26c487;
      border-bottom: 4px solid #26c487;
    }
  }
  .ruleDialog-box1 {
    margin-bottom: 20px;
    .h {
      margin-top: 20px;
      color: #202d40;
      line-height: 1.3;
      .h4 {
        font-size: 14px;
        font-weight: 600;
        color: #202d40;
        line-height: 23px;
      }
    }
    .green {
      border-radius: 3px;
      padding: 12px 3px 12px 9px;
      border: 1px solid rgba(38, 196, 135, 0.48);
    }
  }
  .dia {
    .el-dialog__body {
      padding: 0 40px 40px;
    }
    .el-dialog__header {
      padding: 40px 40px 0px;
    }
    .el-dialog__title::before {
      margin: 0 5px 0 0;
      background: #2cc389;
      border: 2px solid #2cc389;
      display: inline-block;
      vertical-align: middle;
      border-left: 2px solid #2cc389;
      content: "";
      height: 12px;
      line-height: 12px;
    }
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.su {
  line-height: 124px;
  margin: 0 20px;
  width: 1px;
  height: 124px;
  background: #f5f5f5;
}

.mid {
  width: 100%;
  height: 30px;
  background-color: #f5f5f5;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 270px;
  background-image: url("../../../assets/images/preferential_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .left {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .left-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .icon {
      color: #ffa936;
      font-size: 16px;
      line-height: 18px;
    }
    .lock {
      color: #595A6D;
      margin: 10px 0 20px;
      span {
        color: #FAA743;
      }
    }
  }
  .right {
    margin-top: 60px;
    margin-left: 70px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #60666f;
    .li {
      display: flex;
      flex-direction: column;
      height: 110px;
      width: 32%;
      color: #60666f;
      .value {
        padding-top: 20px;
        color: #2e323c;
        font-size: 22px;
        font-weight: bold;
      }
      .unit {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
.paylog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: none;
    border-radius: 2px;
    box-shadow: none;
    box-sizing: border-box;
  }
}
::v-deep .el-dialog__header{
  background: #F7F8FA;
}
::v-deep .el-dialog__body{
  padding: 0 0 30px;
}
.recharge-box{
  position: relative;
  font-size: 14px;
  font-family: 'regular';
  font-weight: 400;
  color: #202D40;
  padding: 0 20px;
  .line{
    margin-top:20px;
    .el-input{
      width:220px;
      ::v-deep .el-input__inner{
        height:32px;
        color: #26C487;
        font-size: 20px;
      }
    }
  }
  .a-right{
    position: absolute;
    right:20px;
  }
}
.recharge-notice {
  width: 100%;
  height: 40px;
  background: #FFFAE8;
  color: #FF7D00;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin: 0 5px 0 17px;
  }
}

::v-deep .el-drawer{
  .el-drawer__header{
    height: 60px;
    background: #F7F8FA;
    padding:0 24px;
    font-size: 20px;
    font-family: 'medium';
    font-weight: 500;
    color: #202D40;
  }
  .drawer-pay{
    padding:32px 24px 0 24px;
  }
  .title{
    font-size: 14px;
    font-family: 'medium';
    font-weight: 500;
    color: #202D40;
    display: flex;
    align-items: center;
    .title-icon{
      width: 3px;
      height: 14px;
      background: #26C487;
      border-radius: 2px;
      margin-right:6px;
    }
  }
  .pay-info{
    font-size: 14px;
    font-family: 'regular';
    font-weight: 400;
    color: #333333;
    div{
      margin-top:20px;
    }
    .info-top{
      margin-right:130px;
    }
  }
}

.head {
  width: 100%;
  box-sizing: border-box;
  background: #f7f8fa;
  border-bottom: 1px solid @hui;
  padding: 18px 20px 11px 17px;
  .content {
    li {
      margin-bottom: 10px;
      line-height: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #595a6d;
      .cld {
        font-size: 12px;
        display: flex;
        color: #595a6d;
      }
      .su {
        margin: 0 14px;
        border: 1px solid #e7ebe7;
        display: inline-block;
        vertical-align: middle;
        border-left: 1px solid @hui;
        content: "";
        height: 12px;
        line-height: 12px;
      }
    }
  }
}
.cardlist {
  align-items: center;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 22px;
  .su {
    margin-top: 2px;
    display: inline-block;
    vertical-align: middle;
    border-right: 1px solid @hui;
    content: "";
    height: 79px;
    padding-left: 10px;
    line-height: 79px;
  }
  .listNum {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0 6px;
    .num {
      font-size: 14px;
      color: #bbbccb;
    }
  }
  .bigl:nth-child(2n) {
    margin-left: 30px;
  }

  .price {
    border-top: 1px dashed #f5f5f5;
    padding-top: 5px;
    display: flex;
    justify-content: flex-end;
    height: 20px;
    line-height: 20px;
    .ge {
      margin-right: 60px;
      font-size: 14px;
      color: #adafad;
    }
    .total {
      margin-right: 20px;
      font-size: 14px;
      font-weight: bold;
      color: @green;
    }
  }
  .lii {
    padding-bottom: 14px;
    border-bottom: 2px dashed @hui;
    display: flex;
    min-width: 350px;
    /* width: 360px; */

    .card {
      text-align: center;
      border: 1px solid @hui;
      width: 86px;
      height: 92px;
      box-sizing: border-box;
    }
    .goodsDetail {
      flex: 1;
      margin-left: 16px;
      line-height: 1.4;
      .h1 {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
      .info {
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .caozuo {
    width: 80px;
    text-align: center;
    white-space: nowrap;
    .zhifu {
      font-size: 14px;
      color: #26c487;
      margin-bottom: 20px;
    }
    .xiangqing {
      font-size: 14px;
    }
  }
}
.pagenation{
  text-align: right;
}

.icon-el-form {
  margin-bottom: 10px;
  .el-form-item {
    width: 24%;
    margin-left: 0;
    ::v-deep .el-form-item__label {
      display: inline-block;
    }
    ::v-deep .el-form-item__content {
      width: 180px;
    }
    .el-select {
      width: 180px;
    }
    .el-range-editor.el-input__inner {
      width: 180px;
    }
    .el-date-editor {
      width: 180px;
    }
  }
}
.center {
  text-align: center;
}
.icon-right {
  text-align: right;
  float: right;
  margin-right: 0;
}

.total-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .total-item {
    width: 48%;
    height: 48px;
    background: linear-gradient(157deg, #60DCAC 0%, #26C487 100%);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 14px;
    color: #ffffff;
  }
  .total-item-yellow {
    background: linear-gradient(157deg, #FAB143 0%, #FAA743 100%);
  }
  .total-info {
    flex: 1;
    padding-left: 16px;
    span {
      font-size: 20px;
      font-family: 'bold';
      font-weight: bold;
      color: #FFFFFF;
      margin: 0 5px;
    }
  }
}
</style>
