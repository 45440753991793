/*
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-11 13:47:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-12 17:43:44
 */
import request from '../request'
export function apiGetPreferentialList(data) {
  return request({
    url: `/pay-v2/mallAmountDetail/amountDetailList`,
    method: 'POST',
    data
  })
}

export function apiGetPreferentialStatistics(params) {
  return request({
    url: `/pay-v2/mallAmountDetail/amountDetailListCount`,
    method: 'GET',
    params: params
  })
}
export function getHuiCoinByUserId(params) {
  return request({
    url: `/pay-v2/mall/amount/getUnLockHuiCoinAmt`,
    method: 'GET',
    params: params
  })
}
export function getPayInfoApi(params) {
  return request({
    url: `/order-v2/mall/order/getPayInfo`,
    method: 'GET',
    params
  })
}
export function rechargeDetailListApi(data) {
  return request({
    url: `/order-v2/mall/order/rechargeDetailList`,
    method: 'POST',
    data
  })
}
// 慧金豆充值去支付
export function coinOrderPayApi(data) {
  return request({
    url: `/order-v2/mall/order/coinOrderPay`,
    method: 'POST',
    data
  })
}

// 查询慧金豆明细关联订单列表
export function getOrderListByCoin(data) {
  return request({
    url: `/order-v2/mall/order/getAmountItem`,
    method: 'POST',
    data
  })
}


// 查询慧金豆
export function getCoinCount(data) {
  return request({
    url: `/pay-v2/mallAmountDetail/amountDetailCount`,
    method: 'POST',
    data
  })
}